import Vue from "vue";
import Vuex from "vuex";
import { SERVER_RESPONSES_ENUM } from "../utils/ServerResponsesUtils.js";
import { PREDEFINED_ROLES_ENUM } from "./../utils/ServerCallsUtils.js";
import { CARNAVAL_SERVICE_NAME } from "./../utils/Constants.js";

Vue.use(Vuex);

function get_user_teams_in_which_he_is_admin(state) {
  var teams_with_admin_rights = [];
  const all_teams = state.user_profile.json_body.roles_in_teams;

  if (null != all_teams) {
    for (let i = 0; i < all_teams.length; i++) {
      const team = all_teams[i];
      if (PREDEFINED_ROLES_ENUM.ADMIN == team.role.name)
        teams_with_admin_rights.push(team);
    }
  }

  return teams_with_admin_rights;
}

const store = new Vuex.Store({
  state: {
    user_profile: {
      response_status: SERVER_RESPONSES_ENUM.WAITING_RESPONSE,
      json_body: null,
    },
    service_name: null,
    service_url: null,
    data_is_updated_toggle: false,
  },
  getters: {
    user_teams_in_which_he_is_admin: (state) => {
      return get_user_teams_in_which_he_is_admin(state);
    },
    is_carnaval_service: (state) => {
      return CARNAVAL_SERVICE_NAME == state.service_name;
    },
    service_name: (state) => {
      return state.service_name;
    },
    service_url: (state) => {
      return state.service_url;
    },
  },
  mutations: {
    setUserProfile(state, user_profile) {
      state.user_profile = user_profile;
    },
    setServiceName(state, service_name) {
      state.service_name = service_name;
    },
    setServiceUrl(state, service_url) {
      state.service_url = service_url;
    },
    toggleDataChange(state) {
      state.data_is_updated_toggle = !state.data_is_updated_toggle;
    },
  },
  actions: {
    updateUserProfile({ commit, dispatch }, user_profile) {
      commit("setUserProfile", user_profile);
      dispatch("toggleDataChange");
    },
    updateServiceName({ commit, dispatch }, service_name) {
      commit("setServiceName", service_name);
      dispatch("toggleDataChange");
    },
    updateServiceUrl({ commit, dispatch }, service_url) {
      commit("setServiceUrl", service_url);
      dispatch("toggleDataChange");
    },
    toggleDataChange({ commit }) {
      commit("toggleDataChange");
    },
  },
});

export default store;

export const SERVER_RESPONSES_ENUM = Object.freeze({
  //----------------
  // Common for all calls
  //----------------
  REQUEST_NOT_SEND_YET: 1,
  WAITING_RESPONSE: 2,
  RESPONSE_OK: 3,
  ERROR_USER_NOT_LOGGED_IN: 4,
  ERROR_UNKNOWN_OR_UNSPECIFIED: 5,
  //----------------
  // API-specific (the same response code can be used for several APIs)
  //----------------
  // Response({'detail': 'Activation link is invalid!'}, status=status.HTTP_400_BAD_REQUEST)
  // The token is invalid!
  ERROR_TICKET_INVALID: 6,
  // Response({'detail': 'Incorrect password provided.'}, status=status.HTTP_400_BAD_REQUEST)
  ERROR_PASSWORD_INVALID: 7,
  // The user is already registered
  // ValidationError({"detail": "This email already exist"})
  // detail	"There is already an identical request ongoing"
  ERROR_ALREADY_EXIST: 8,
  // email	[ "Enter a valid email address." ]
  ERROR_EMAIL_WRONG_FORMAT: 9,
  // Response({'detail': 'Failed to send verification email'})
  ERROR_EMAIL_NOT_SEND: 10,
  // detail	[ "This email does not exist" ]
  // This user does not exist or is not registered yet
  ERROR_DOES_NOT_EXIST: 11,
  // CustomValidationError(detail="You cannot send an invitation to yourself")
  ERROR_INVITATION_TO_YOURSELF: 12,
  // CustomValidationError(detail="The receiver is already in the team")
  ERROR_USER_ALREADY_IN_TEAM: 13,
  // raise CustomValidationError(detail="There is already a request sent to invite this user to this team")
  ERROR_USER_ALREADY_INVITED_IN_TEAM: 14,
  // {'detail': 'You cannot remove the only admin of a team'}
  ERROR_REMOVE_LAST_ADMIN: 15,
  // Response({'detail': 'The limit of attempts per code has been reached. Re-generate the code.'}
  ERROR_TOO_MANY_ATTEMPTS: 16,
  // "The code has expired. Generate it again."
  ERROR_EXPIRED: 17,
  // Response({'detail': 'The activation code can not be verified.'}
  ERROR_CODE_CAN_NOT_BE_VERIFIED: 18
});

// TODO: treat update user profile errors !
export function transformErrorToServerResponseEnum(error) {
  var error_enum_transformed =
    SERVER_RESPONSES_ENUM.ERROR_UNKNOWN_OR_UNSPECIFIED; // unknown error with no details provided

  if (null != error.response && null != error.response.data) {
    const error_data = error.response.data;
    var error_detail_text = "";

    if (null != error_data.detail) {
      if (Array.isArray(error_data.detail) && error_data.detail.length > 0) {
        error_detail_text = error_data.detail[0];
      } else {
        error_detail_text = error_data.detail;
      }
    } else if (
      null != error_data.email &&
      Array.isArray(error_data.email) &&
      error_data.email.length > 0
    ) {
      error_detail_text = error_data.email[0];
    } else if (
      null != error_data.receiver_email &&
      Array.isArray(error_data.receiver_email) &&
      error_data.receiver_email.length > 0
    ) {
      error_detail_text = error_data.receiver_email[0];
    } else if (
      null != error_data.user_email &&
      Array.isArray(error_data.user_email) &&
      error_data.user_email.length > 0
    ) {
      error_detail_text = error_data.user_email[0];
    }

    if (error_detail_text.includes("credentials were not provided")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_USER_NOT_LOGGED_IN;
    } else if (error_detail_text.includes("Enter a valid email")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_EMAIL_WRONG_FORMAT; // email	[ "Enter a valid email address." ]
    } else if (error_detail_text.includes("Incorrect password")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_PASSWORD_INVALID; // Response({'detail': 'Incorrect password provided.'}
    } else if (
      error_detail_text.includes("token is invalid") ||
      error_detail_text.includes("link is invalid") ||
      error_detail_text.includes("code is invalid")
    ) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_TICKET_INVALID;
    } else if (
      error_detail_text.includes("already registered") ||
      error_detail_text.includes("already exist") ||
      error_detail_text.includes("identical request ongoing") ||
      error_detail_text.includes("already registered")
    ) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_ALREADY_EXIST;
    } else if (error_detail_text.includes("does not exist")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_DOES_NOT_EXIST;
    } else if (error_detail_text.includes("invitation to yourself")) {
      error_enum_transformed =
        SERVER_RESPONSES_ENUM.ERROR_INVITATION_TO_YOURSELF;
    } else if (error_detail_text.includes("receiver is already in the team")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_USER_ALREADY_IN_TEAM;
    } else if (
      error_detail_text.includes(
        "request sent to invite this user to this team"
      )
    ) {
      error_enum_transformed =
        SERVER_RESPONSES_ENUM.ERROR_USER_ALREADY_INVITED_IN_TEAM;
    } else if (error_detail_text.includes("cannot remove the only admin")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_REMOVE_LAST_ADMIN;
    } else if (error_detail_text.includes("limit of attempts")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_TOO_MANY_ATTEMPTS;
    } else if (error_detail_text.includes("code has expired")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_EXPIRED;
    } else if (error_detail_text.includes("code can not be verified")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_CODE_CAN_NOT_BE_VERIFIED;
    } else if (error_detail_text.includes("Failed to send verification email")) {
      error_enum_transformed = SERVER_RESPONSES_ENUM.ERROR_EMAIL_NOT_SEND;
    }
  }

  return error_enum_transformed;
}

export function isWaitingServerResponse(server_response) {
  return SERVER_RESPONSES_ENUM.WAITING_RESPONSE == server_response;
}

export function isResponseReceived(server_response) {
  return (
    server_response != SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
    server_response != SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
  );
}

export function isResponseError(server_response) {
  return (
    isResponseReceived(server_response) &&
    server_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
  );
}

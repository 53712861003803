import axios from "axios";
import CSRF_TOKEN from "./csrf_token.js";
import {
  SERVER_RESPONSES_ENUM,
  transformErrorToServerResponseEnum,
} from "./ServerResponsesUtils.js";

// This is needed to fix the "CSRF token missing or incorrect" error
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRFToken"] = CSRF_TOKEN;

// -------------------------
// Public
// -------------------------

export const REQUEST_TYPE = Object.freeze({
  REGISTARTION: 1,
  PASSWORD_RESET: 2,
});

export const PREDEFINED_ROLES_ENUM = Object.freeze({
  ADMIN: "admin",
  OWNER: "owner",
  USER: "user",
});

export const HISTORY_EVENTS_TYPES_ENUM = Object.freeze({
  CREATE: "+",
  REMOVE: "-",
  CHANGE: "~",
});

export function getConfirmationTicketFromURL(location) {
  const params = new URLSearchParams(location.search);

  // the ticket contains 2 parts: the uid and the actual token
  if (params.has("p1") && params.has("p1")) {
    return params.get("p1") + "/" + params.get("p2");
  }

  return "";
}

export async function getCheckConfirmationTicketResponse(ticket, request_type) {
  let url_req = "";

  switch (request_type) {
    case REQUEST_TYPE.PASSWORD_RESET:
      url_req = "api/check_password_reset_token/" + ticket;
      break;
    default:
      url_req = "api/check_registration_token/" + ticket;
      break;
  }

  var confirmation_response = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;
  await axios
    .get(url_req)
    .then((response) => {
      confirmation_response = SERVER_RESPONSES_ENUM.RESPONSE_OK;
    })
    .catch((error) => {
      confirmation_response = transformErrorToServerResponseEnum(error);
    });

  return confirmation_response;
}

export async function sendRequestWithTicket(
  ticket,
  request_body,
  request_type
) {
  let url_req = "";

  switch (request_type) {
    case REQUEST_TYPE.PASSWORD_RESET:
      url_req = "api/password_change/" + ticket;
      break;
    default:
      url_req = "api/activate/" + ticket;
      break;
  }

  return await sendPostRequestNoResponseData(url_req, request_body);
}

export async function sendRequestTeamInvitation(service, team, role, email) {
  const url_req = "api/invitation_request/";

  var request_body = {
    service_name: service,
    team_name: team,
    role_name: role,
    receiver_email: email,
  };

  return await sendPostRequestResponseData(url_req, request_body);
}

export async function verifyCaptcha(captcha_token) {
  // returns true if the Google captcha service validates the captcha token

  const url_req = "api/verify_captcha/";
  var request_body = { token: captcha_token };

  const response = await sendPostRequestResponseData(url_req, request_body);
  if (
    null != response &&
    null != response.json_body &&
    null != response.json_body.success
  ) {
    return response.json_body.success;
  } else {
    return false;
  }
}

export async function getUsersInTeams() {
  const url_req = "api/team/";
  return await simpleGetWithArrayResponseData(url_req);
}

export async function getHistoryTeam() {
  const url_req = "api/history_team/";
  return await simpleGetWithArrayResponseData(url_req);
}

export async function getInvitationRequestsSent() {
  const url_req = "api/invitation_request/";
  return await simpleGetWithArrayResponseData(url_req, true);
}

export async function getInvitationRequestsRecieved() {
  const url_req = "api/invitation_received/";
  return await simpleGetWithArrayResponseData(url_req, true);
}

export async function getUserProfile() {
  // returns a user account
  const url_req = "api/full/profile/";

  var user_profile = {};
  user_profile.response_status = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;
  user_profile.json_body = null;

  await axios
    .get(url_req)
    .then((response) => {
      user_profile.response_status = SERVER_RESPONSES_ENUM.RESPONSE_OK;
      if (null != response && null != response.data) {
        user_profile.json_body = response.data;
      }
    })
    .catch((error) => {
      user_profile.response_status = transformErrorToServerResponseEnum(error);
    });

  return user_profile;
}

export async function acceptInvitationRequest(invitation_id) {
  const url_req = "api/invitation_received/" + invitation_id + "/";
  const request_body = { accepted: true };
  return await sendPutRequestNoResponseData(url_req, request_body);
}

export async function declineInvitationRequest(invitation_id) {
  const url_req = "api/invitation_received/" + invitation_id + "/";
  const request_body = { accepted: false };
  return await sendPutRequestNoResponseData(url_req, request_body);
}

export async function sendConfirmationEmail(email) {
  const url_req = "api/signup/";
  const request_body = { email: email };
  return await sendPostRequestNoResponseData(url_req, request_body);
}

export async function activateUserAccountByCode(
  activation_code,
  password,
  email
) {
  const url_req = "api/activate_by_code/";
  const request_body = {
    activation_code: activation_code,
    email: email,
    password: password,
  };
  return await sendPostRequestNoResponseData(url_req, request_body);
}

export async function getCheckActivationCodeResponse(activation_code, email) {
  const url_req = "api/check_activation_code/";
  const request_body = {
    activation_code: activation_code,
    email: email,
  };
  return await sendPostRequestNoResponseData(url_req, request_body);
}

export async function sendContactEmail(name, email, company, phone, message) {
  const url_req = "api/send_contact_email/";
  const request_body = {
    user_name: name,
    user_email: email,
    user_company: company,
    user_phone: phone,
    user_msg: message,
  };

  return await sendPostRequestNoResponseData(url_req, request_body);
}

export async function sendPasswordResetEmail(email) {
  const url_req = "api/password_reset/";
  const request_body = { email: email };
  return await sendPostRequestNoResponseData(url_req, request_body);
}

export async function updateUserProfile(updated_user_profile) {
  const url_req = "api/profile/";
  const form_data = transform_user_profile_to_form_data(updated_user_profile);
  return await sendPutRequestNoResponseData(url_req, form_data);
}

export async function deleteUserFromRole(role_id) {
  const url_req = "api/team/" + role_id + "/";
  return await sendDeleteRequestNoResponseData(url_req);
}

export async function deleteInvitation(request_id) {
  const url_req = "api/invitation_request/" + request_id + "/";
  return await sendDeleteRequestNoResponseData(url_req);
}

export async function changeUserRoleApi(role_id, new_role_name) {
  const url_req = "api/team/" + role_id + "/";
  const request_body = { new_role: new_role_name };
  return await sendPutRequestNoResponseData(url_req, request_body);
}

// -------------------------
// Private
// -------------------------

function transform_user_profile_to_form_data(updated_user_profile) {
  let form_data = new FormData();

  for (var key in updated_user_profile) {
    const value = updated_user_profile[key];
    form_data.append(key, null != value ? value : "");
  }

  return form_data;
}

async function sendPostRequestNoResponseData(url_req, request_body) {
  var response_recieved = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

  await axios
    .post(url_req, request_body)
    .then(() => {
      response_recieved = SERVER_RESPONSES_ENUM.RESPONSE_OK;
    })
    .catch((error) => {
      response_recieved = transformErrorToServerResponseEnum(error);
    });

  return response_recieved;
}

async function sendPutRequestNoResponseData(url_req, request_body) {
  var response_recieved = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

  await axios
    .put(url_req, request_body)
    .then(() => {
      response_recieved = SERVER_RESPONSES_ENUM.RESPONSE_OK;
    })
    .catch((error) => {
      response_recieved = transformErrorToServerResponseEnum(error);
    });

  return response_recieved;
}

async function sendPostRequestResponseData(url_req, request_body) {
  var response_status_and_data = {};
  response_status_and_data.response_status =
    SERVER_RESPONSES_ENUM.WAITING_RESPONSE;
  response_status_and_data.json_body = null;

  await axios
    .post(url_req, request_body)
    .then((response) => {
      if (null != response && null != response.data) {
        response_status_and_data.response_status =
          SERVER_RESPONSES_ENUM.RESPONSE_OK;
        if (null != response && null != response.data) {
          response_status_and_data.json_body = response.data;
        }
      }
    })
    .catch((error) => {
      response_status_and_data.response_status = transformErrorToServerResponseEnum(
        error
      );
    });

  return response_status_and_data;
}

async function simpleGetWithArrayResponseData(
  url_req,
  reverse_array_flag = false
) {
  var response_data = null;

  await axios
    .get(url_req)
    .then((response) => {
      if (null != response && null != response.data) {
        response_data = response.data;
        if (
          reverse_array_flag &&
          null != response_data &&
          Array.isArray(response_data)
        ) {
          response_data.reverse();
        }
      } else {
        response_data = [];
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return response_data;
}

async function sendDeleteRequestNoResponseData(url_req) {
  var response_recieved = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

  await axios
    .delete(url_req)
    .then(() => {
      response_recieved = SERVER_RESPONSES_ENUM.RESPONSE_OK;
    })
    .catch((error) => {
      response_recieved = transformErrorToServerResponseEnum(error);
    });

  return response_recieved;
}

import Vue from "vue";
import Router from "vue-router";
import HomePage from "../pages_views/HomePage.vue";
import HomePageAndroidWebView from "../pages_views/HomePageAndroidWebView.vue";
import FaqPage from "../pages_views/FaqPage.vue";
import TermsOfServiceOrPrivacyPolicyPage from "../pages_views/TermsOfServiceOrPrivacyPolicyPage.vue";
import PasswordResetRequestPage from "../pages_views/PasswordResetRequestPage.vue";
import ConfirmTokenAndSendRequestPage from "../pages_views/ConfirmTokenAndSendRequestPage.vue";
import { REQUEST_TYPE } from "../utils/ServerCallsUtils";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
    {
      path: "/androidWebView",
      name: "androidWebView",
      component: HomePageAndroidWebView,
    },
    // the url of the faq is kept for backward compatibility
    // TODO: delete it when no longer referenced 
    {
      path: "/faq",
      name: "faq",
      component: FaqPage,
    },
    {
      path: "/contact",
      name: "contact",
      component: FaqPage,
    },
    {
      path: "/terms",
      name: "terms",
      component: TermsOfServiceOrPrivacyPolicyPage,
      props: { isPrivacyPolicy: false },
    },
    {
      path: "/policy",
      name: "policy",
      component: TermsOfServiceOrPrivacyPolicyPage,
      props: { isPrivacyPolicy: true },
    },
    {
      path: "/confirm_email",
      name: "confirmation",
      component: ConfirmTokenAndSendRequestPage,
      props: { request_type: REQUEST_TYPE.REGISTARTION },
    },
    {
      path: "/password_reset",
      name: "password",
      component: PasswordResetRequestPage,
    },
    {
      path: "/password_reset_token",
      name: "password_reset_token",
      component: ConfirmTokenAndSendRequestPage,
      props: { request_type: REQUEST_TYPE.PASSWORD_RESET },
    },
  ],
});

export default router;
